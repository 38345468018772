import { GetStaticProps, NextPage } from 'next';
import { initializeBuilder, cacheTime, cacheArgs } from 'utils/builder';
import { pageModel } from 'types/pageModels';
import builder, {
  BuilderComponent,
  BuilderPage,
  useIsPreviewing,
} from '@builder.io/react';
import Error from 'styleguide/src/components/Error';
import Layout from '../components/Layout';
import Page404 from './404';
import 'components/BuilderComponents/builderInit';
import Seo from 'components/Seo';
import { LayoutProps, getLayoutData } from 'utils/layoutData';
import { getBuilderPages } from 'utils/getLayoutPages';
import { isProduction } from 'common/constants/environment';

initializeBuilder();

interface Props {
  page: BuilderPage;
  layoutProps: LayoutProps;
}

export const getStaticProps: GetStaticProps<Props> = async ({ params }) => {
  const pagePath = params?.page
    ? Array.isArray(params.page)
      ? params.page
      : [params.page]
    : [];

  try {
    const pagePromise = builder
      .get(pageModel, {
        ...cacheArgs,
        userAttributes: {
          urlPath: '/' + pagePath.join('/') || '',
        },
        options: {
          includeUnpublished: !isProduction,
        },
      })
      .toPromise();

    const layoutPropsPromise = await getLayoutData();

    const [page, layoutProps] = await Promise.all([
      pagePromise,
      layoutPropsPromise,
    ]);

    return {
      props: {
        page: page || null,
        layoutProps: layoutProps || null,
      },
      notFound: !page,
      revalidate: cacheTime,
    };
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('Failed to get page data', pagePath, err);
    return {
      notFound: true,
    };
  }
};

export async function getStaticPaths() {
  const paths = await getBuilderPages(pageModel);

  return {
    paths,
    fallback: 'blocking',
  };
}

const Page: NextPage<Props> = ({ page, layoutProps }) => {
  // This flag indicates if you are viewing the page in the Builder editor.
  const isPreviewing = useIsPreviewing();

  if (!page && !isPreviewing) {
    return <Page404 {...layoutProps} />;
  }

  const Warnings =
    process.env.NODE_ENV === 'development' && !page ? (
      <Error
        errorMessage={`Warning! Page not found. Either you haven't published this page or you need to edit your BUILDER_API_MODEL environment variable.`}
      />
    ) : null;

  return (
    <>
      <Seo pageData={page?.data} />
      <Layout navColor={page?.data.navColor} {...layoutProps}>
        {Warnings}
        <BuilderComponent model={pageModel} content={page} />
      </Layout>
    </>
  );
};

export default Page;
